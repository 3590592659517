<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this user?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    form Dialog-->
    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar flat>
          <v-toolbar-title class="grey--text">
            <span class="text-h3">{{ formTitle }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="close"
          >
            <v-icon class="red--text">
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <validation-observer
            ref="observer"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="editedItem.firstName"
                      placeholder="Last Name"
                      prepend-inner-icon="mdi-account-box"
                      outlined
                      :error-messages="errors"
                      label="First Name"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="editedItem.lastName"
                      placeholder="Last Name"
                      :error-messages="errors"
                      prepend-inner-icon="mdi-account-outline"
                      outlined
                      label="Last Name"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="editedItem.email"
                      placeholder="Email"
                      prepend-inner-icon="mdi-at"
                      :error-messages="errors"
                      outlined
                      label="Email"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone Number"
                    :rules="{
                      required: true,
                      digits: 10,
                    }"
                  >
                    <v-text-field
                      v-model="editedItem.phone"
                      placeholder="Phone Number"
                      :counter="10"
                      return-masked-value
                      mask="##########"
                      :error-messages="errors"
                      prepend-inner-icon="mdi-phone"
                      outlined
                      label="Phone Number"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  v-if="editedIndex === -1"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <v-text-field
                      id="password"
                      v-model="editedItem.password"
                      outlined
                      preppend-icon="mdi-lock"
                      :error-messages="errors"
                      name="password"
                      label="Password"
                      :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      @click:append="() => (isPasswordVisible = !isPasswordVisible)"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  v-if="editedIndex === -1"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirm Password"
                    rules="required"
                  >
                    <v-text-field
                      id="password"
                      v-model="editedItem.confirmPassword"
                      outlined
                      preppend-icon="mdi-lock"
                      :error-messages="errors"
                      name="confirm_password"
                      label="Confirm Password"
                      :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :rules="[comparePasswords]"
                      @click:append="() => (isPasswordVisible = !isPasswordVisible)"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="User Role"
                    rules="required"
                  >
                    <v-select
                      v-model="editedItem.role"
                      outlined
                      :error-messages="errors"
                      :items="roles"
                      label="Select Role"
                      single-line
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="onAddClient"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    form Dialog-->
    <!--   Reset Form Dialog-->
    <v-dialog
      v-model="resetDialog"
      max-width="600px"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar flat>
          <v-toolbar-title class="grey--text">
            <span class="text-h3">Reset Password</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="closeReset"
          >
            <v-icon class="red--text">
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <validation-observer
            ref="observer"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Current Password"
                    rules="required"
                  >
                    <v-text-field
                      id="password"
                      v-model="editedItem.currentPassword"
                      outlined
                      preppend-icon="mdi-lock"
                      :error-messages="errors"
                      name="currentPassword"
                      label="Current Password"
                      :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      @click:append="() => (isPasswordVisible = !isPasswordVisible)"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirm Password"
                    rules="required"
                  >
                    <v-text-field
                      id="password"
                      v-model="editedItem.newPassword"
                      outlined
                      preppend-icon="mdi-lock"
                      :error-messages="errors"
                      name="new_password"
                      label="New Password"
                      :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      @click:append="() => (isPasswordVisible = !isPasswordVisible)"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirm Password"
                    rules="required"
                  >
                    <v-text-field
                      id="password"
                      v-model="editedItem.confirmPassword"
                      outlined
                      preppend-icon="mdi-lock"
                      :error-messages="errors"
                      name="confirm_password"
                      label="Confirm Password"
                      :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :rules="[compareNewPasswords]"
                      @click:append="() => (isPasswordVisible = !isPasswordVisible)"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="User Role"
                    rules="required"
                  >
                    <v-select
                      v-model="editedItem.role"
                      outlined
                      :error-messages="errors"
                      :items="roles"
                      label="Select Role"
                      single-line
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeReset"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="onResetPassword"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Reset Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Form Dialog End-->
    <!--    Delete Dialog Start-->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this client?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Delete Dialog end-->

    <!--    All Dialogs End-->
    <!--    Base Material Start -->
    <div v-if="fetchup">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <base-material-card
      v-else
      color="black"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-row class="align-content-md-space-between">
            <v-col
              cols="12"
              md="9"
              sm="6"
            >
              <div class="text-h3 font-weight-light">
                <v-icon
                  left
                  class="primary--text"
                >
                  mdi-account-multiple
                </v-icon> Manage Users <i class="text-caption">Total: <b>{{ total }}</b></i>
              </div>
              <div class="text-subtitle-1 font-weight-light">
                Please manage all Users
              </div>
            </v-col>
            <v-col
              md="3"
              sm="6"
              style="text-align:right"
            >
              <v-btn
                color="primary"
                dark
                outlined
                class="mb-2 subheading font-weight-bold"
                @click="openDialog"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add New User
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
      <v-data-table
        :headers="headers"
        :items="users"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            text
            class="orange--text text--darken-3 cpoint"
            @click="resetPass(item)"
          >
            <v-icon>
              mdi-lock-reset
            </v-icon> Reset Password
          </v-btn>
          <v-btn
            small
            text
            class="grey--text"
            @click="editItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>
          <v-btn
            small
            text
            class="red--text"
            @click="deleteItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            Delete
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn
            text
            color="primary--text"
            @click="fetchUsers"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
    <!--    Base Material End-->
  </v-container>
</template>
<script>
  /* eslint-disable */
  import { mapGetters } from 'vuex'
  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })
  const axios = require('axios')
  export default {
    name: 'ManageUsers',
    data: () => ({
      total:0,
      fetchup: false,
      menu: false,
      dialog: false,
      dialogDelete: false,
      subscribeDialog: false,
      resetDialog: false,
      isPasswordVisible: false,
      sub: {
        package: { _id: '', name: '', price: '' },
        startDate: null,
        endDate: null,
      },
      roles: [
        'Superadmin',
        'Staff',
        'Receptionist',
      ],
      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Phone No.', value: 'phone' },
        { text: 'role', value: 'role' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      users: [],
      editedIndex: -1,
      editedItem: {
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        newPassword: '',
        currentPassword: '',
        phone: '',
        email: '',
        role: '',
      },
      defaultItem: {
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        phone: '',
        email: '',
        role: '',
        company:'',
        branch:'',
      },
    }),

    computed: {
      ...mapGetters(['getPackages']),
      comparePasswords(){
        return this.editedItem.password !== this.editedItem.confirmPassword ? 'Passwords do not match' :''
      },
      compareNewPasswords(){
        return this.editedItem.newPassword !== this.editedItem.confirmPassword ? 'Passwords do not match' :''
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Add New User' : 'Edit User'
      },
    },

    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.fetchUsers()
    },

    methods: {
      openDialog(){
        this.dialog = true;
      },
      resetPass(){
        this.resetDialog = true;
      },
      onSubscribe () {
        this.subscribeDialog = false
      },
      saveDate (date) {
        this.$refs.menu.save(date)
      },
      viewSubDetails () {
        this.subscribeDialog = true
        this.$store.dispatch('fetchPackages')
      },
      fetchUsers () {
        this.fetchup = true
        const self = this
        axios.get(this.apiSet.getStaff)
          .then(response => {
            const obj = response.data.users
            self.users = obj
            self.total = obj.length
            self.fetchup = false
          }).catch(
          e => {
            self.fetchup = false
            this.err.push(e)
          },
        )
      },
      onAddClient () {
        if (this.editedIndex > -1) {
          this.updateClient()
        } else {
          const self = this
          this.$refs.observer.validate().then((result) => {
            if (result) {
              this.loader = true
              const catData = this.editedItem

              axios.post(this.apiSet.staff, {...catData,
                company:this.userData.company._id,
                branch:this.userData.branch._id})
                .then(function (response) {
                  self.close()
                  self.fetchUsers()
                  self.fetchup = false
                  self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
                })
                .catch(error => {
                  self.loader = false
                  self.err.push(error)
                })
            } else {
              self.$store.commit('setSnack', { t: 'Form error, Please correct errors.', c: 'red darken-3' })
              return false
            }
          })
        }
      },
      updateClient () {
        const self = this
        this.$refs.observer.validate().then((result) => {
          if (result) {
            this.loader = true
            const catData = this.editedItem

            axios.patch(this.apiSet.users,
              {...catData,
                company:this.userData.company._id,
                branch:this.userData.branch._id})
              .then(function (response) {
                self.close()
                self.fetchUsers()
                self.loader = false
                self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
              })
              .catch(error => {
                self.loader = false
                console.log(error)
              })
          } else {
            self.$store.commit('setSnack', { t: 'Error. Please correct form errors.', c: 'red darken-3' })

            return false
          }
        })
      },
      onResetPassword () {
        const self = this
        this.$refs.observer.validate().then((result) => {
          if (result) {
            this.loader = true
            const catData = this.editedItem

            axios.post(this.apiSet.changePassword, catData)
              .then(function (response) {
                self.closeReset()
                self.fetchUsers()
                self.fetchup = false
                self.$store.commit('setSnack', { t: 'Password Successfully changed.', c: 'green darken-3' })
              })
              .catch(error => {
                self.loader = false
                self.err.push(error)
              })
          } else {
            self.$store.commit('setSnack', { t: 'Form error, Please correct errors.', c: 'red darken-3' })
            return false
          }
        })
      },

      editItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.users.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeSubDialog () {
        this.subscribeDialog = false
      },
      closeReset () {
        this.resetDialog = false
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.users[this.editedIndex], this.editedItem)
        } else {
          this.users.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
